<template>
    <div class="bg-white">
        <div class="container mx-auto h-32 flex ">
            <div class="mx-auto w-2/4 flex items-center">
            
                <div class="flex-grow" style="z-index: 1001;">
                    <a
                        class="inline-block cursor-pointer"
                        @click="$events.emit('logoClicked')">
                        
                        <img src="logo.svg" class="h-16">
                    </a>
                </div>
                <div class="flex-none flex" >
                    <div 
                        v-show="!searchBox"
                        @click="toggleSearchBox()" 
                        class="button flex items-center justify-center"
                        style="padding: 0!important; width: 2.7rem;">
                        
                        <svg class="w-4 h-4" viewBox="0 0 38 36" xmlns="http://www.w3.org/2000/svg">
                            <path 
                                d="M 15.448527,0.01104935 A 14.875,14.875 0 0 0 3.8762672,4.8606587 v 0.00195 a 14.875,14.875 0 0 0 0.98437,21.0136713 14.875,14.875 0 0 0 19.9843798,0.02148 l 11.38281,10.365239 c 0.17922,0.162898 0.45595,0.150987 0.61914,-0.0293 l 1.3457,-1.478515 c 0.16316,-0.17915 0.15008,-0.455979 -0.0293,-0.619141 l -11.375,-10.357422 A 14.875,14.875 0 0 0 24.889927,3.8762807 14.875,14.875 0 0 0 15.448517,0.01104635 Z m -0.95508,3.02734375 a 11.84467,11.84467 0 0 1 8.35547,3.0800781 11.84467,11.84467 0 0 1 0.78516,16.7324218 A 11.84467,11.84467 0 0 1 6.9016572,23.634096 11.84467,11.84467 0 0 1 6.1164972,6.9016744 11.84467,11.84467 0 0 1 14.493447,3.0383931 Z"
                            />
                        </svg>
                    </div>
                    
                    <div 
                        v-show="searchBox"
                        class="searchBox">
                        
                        <svg class="w-4 h-4 mr-4 inline-block" viewBox="0 0 38 36" xmlns="http://www.w3.org/2000/svg">
                            <path 
                                d="M 15.448527,0.01104935 A 14.875,14.875 0 0 0 3.8762672,4.8606587 v 0.00195 a 14.875,14.875 0 0 0 0.98437,21.0136713 14.875,14.875 0 0 0 19.9843798,0.02148 l 11.38281,10.365239 c 0.17922,0.162898 0.45595,0.150987 0.61914,-0.0293 l 1.3457,-1.478515 c 0.16316,-0.17915 0.15008,-0.455979 -0.0293,-0.619141 l -11.375,-10.357422 A 14.875,14.875 0 0 0 24.889927,3.8762807 14.875,14.875 0 0 0 15.448517,0.01104635 Z m -0.95508,3.02734375 a 11.84467,11.84467 0 0 1 8.35547,3.0800781 11.84467,11.84467 0 0 1 0.78516,16.7324218 A 11.84467,11.84467 0 0 1 6.9016572,23.634096 11.84467,11.84467 0 0 1 6.1164972,6.9016744 11.84467,11.84467 0 0 1 14.493447,3.0383931 Z"
                            />
                        </svg>
                        
                        <input 
                            type="text" 
                            style="padding: 0;  border: none;"
                            v-model="searchString" 
                            ref="searchboxinput"
                            autofocus
                            @change="search()"
                        />
                        <i 
                            @click="toggleSearchBox()" 
                            class="fas fa-times ml-4"
                        />
                    </div>
                    
                    <div 
                        @click="toggleMenu()" 
                        class="button ml-6 flex items-center">
                        
                        <svg class="w-4 mr-2" viewBox="0 0 48 32" xmlns="http://www.w3.org/2000/svg">
                            <path 
                                d="M 0,0 V 4.9999975 H 47.000013 V 0 Z m 0,13.999998 v 4.999974 H 47.000013 V 13.999998 Z M 0,27.99998 v 5.000013 H 47.000013 V 27.99998 Z"
                            />
                        </svg>
                        
                        Meny
                    </div>
                    <div 
                        v-if="showMenu"
                        @click="toggleMenu()" 
                        class="button link ml-6"
                        style="z-index: 1001;">
                        
                        <i class="fas fa-times mr-2" />
                        Lukk
                    </div>
                    
                    <div 
                        v-if="showMenu"
                        class="absolute inset-0 pt-32" 
                        style="z-index: 1000; background: #00938c;">
                        
                        <div class="w-full border-t pb-24" style="border-color: #9cd9c2;">
                            
                        </div>
                        
                        <div 
                            @click="toggleMenu()" 
                            class="container mx-auto flex" >
                            <div class="mx-auto w-2/4 flex">
                                <div class="w-1/3 pr-12">
                                    <h3 class="text-lo-green-dark ">REGELVERK</h3>
                                    
                                    <div v-for="a in wordpressPages.regeleverket">
                                        <a 
                                            @click="openLink({ path: '/artikkel/' + a.id})"
                                            class="text-white text-sm my-4 block cursor-pointer">
                                            {{a.title.rendered}}
                                        </a>
                                        
                                        <!-- <router-link 
                                            :to="{ path: '/artikkel/' + a.id}"
                                            @click.native="$router.go()"
                                            class="text-white text-sm my-4 block"
                                            :target="$route.name == 'veileder' ? '_blank' : ''">
                                            {{a.title.rendered}}
                                        </router-link> -->
                                    </div>
                                </div>
                                <div class="w-1/3 pr-12">
                                    <h3 class="text-lo-green-dark ">LÆRING</h3>
                                    
                                    <div v-for="a in wordpressPages.ressurser">
                                        <a 
                                            @click="openLink({ path: '/artikkel/' + a.id})"
                                            class="text-white text-sm my-4 block cursor-pointer">
                                            {{a.title.rendered}}
                                        </a>
                                    </div>
                                </div>
                                <div class="w-1/3 ">
                                    <h3 class="text-lo-green-dark ">OM VERKTØYET</h3>
                                    
                                    <div v-for="a in wordpressPages.om">
                                        <a 
                                            @click="openLink({ path: '/artikkel/' + a.id})"
                                            class="text-white text-sm my-4 block cursor-pointer">
                                            {{a.title.rendered}}
                                        </a>
                                    </div>
                                </div>
                         </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div 
            v-if="searchResults"
            class="mx-auto white border-b border-t py-8">
            
            <div class="mx-auto w-2/4 ">
                <h3 class="text-lo-green-darkest">
                    Søk etter 
                    <em class="text-black">{{searchString}}</em>
                    ga {{searchResults ? searchResults.length : 0}} treff
                </h3>
                
                <div v-if="searchResults && searchResults.length">
                    <a 
                        v-for="result in searchResults"
                        @click="openLink({ path: '/artikkel/' + result.id})"
                        class="block text-lo-pink mb-6 cursor-pointer">
                        
                        <span>{{result.title.rendered}}</span>
                        <p class="text-lo-green-darkest" v-html="result.excerpt.rendered"></p>
                    </a>
                    <!-- 
                    <router-link 
                        v-for="result in searchResults"
                        :to="{ path: 'artikkel/'+result.id}"
                        class="block text-lo-pink mb-6"
                        :target="$route.name == 'veileder' ? '_blank' : ''">
                        <span>{{result.title.rendered}}</span>
                        <p class="text-lo-green-darkest" v-html="result.excerpt.rendered"></p>
                    </router-link>
                     -->
                </div>
                
            </div>
        </div>
        
    </div>
</template>

<script>
    export default {
        data(){
            return {
                showMenu: false,
                wordpressPages: {
                    om: null, //113
                    regeleverket: null, //109
                    ressurser: null, //111
                },
                searchBox: false,
                searchString: '',
                searchResults: null,
            }
        },
        
        methods: {
            toggleMenu(){
                this.showMenu = !this.showMenu;
                this.searchString = '';
            },
            toggleSearchBox(){
                this.searchBox = !this.searchBox;
                this.searchString = '';
                this.searchResults = null;
                // console.log('this.$refs.searchboxinput', this.$refs.searchboxinput);
                this.$nextTick( () => {
                    this.$refs.searchboxinput.focus();
                });
            },
            async search(){
                if (this.searchString) { // && searchString.length > 0
                    const results =  await this.searchPages(this.searchString);
                    this.searchResults = null;
                    
                    const searchResults = [];
                    
                    results.forEach( r => {
                        // is in pages?
                        let page;
                        
                        if ( this.wordpressPages.om.find( p => p.id == r.id ) ) {
                            page = this.wordpressPages.om.find( p => p.id == r.id );
                        }
                        
                        if ( this.wordpressPages.regeleverket.find( p => p.id == r.id ) ) {
                            page = this.wordpressPages.regeleverket.find( p => p.id == r.id );
                        }
                        
                        if ( this.wordpressPages.ressurser.find( p => p.id == r.id ) ) {
                            page = this.wordpressPages.ressurser.find( p => p.id == r.id );
                        }
                        
                        if (page) {
                            searchResults.push(page);
                        }
                    });
                    
                    // if (searchResults.length) {
                    // }
                    this.searchResults = searchResults;
                }
            }
        },
        
        async mounted(){
            if ( !this.$store.state.pages ) {
                // console.log('mounted in Navigation');
                this.$store.state.pages = await this.fetchPages();
            }
            this.wordpressPages.om = this.$store.state.pages.filter( p => p.parent == 113);
            this.wordpressPages.regeleverket = this.$store.state.pages.filter( p => p.parent == 109);
            this.wordpressPages.ressurser = this.$store.state.pages.filter( p => p.parent == 111);
            
            // this.searchBox = !this.searchBox;
            // this.$nextTick( () => {
            //     this.$refs.searchboxinput.focus();
            // });
            // console.log(this.$route.name == 'veileder');
        }
    }
</script>


<style lang="scss" scoped>
    .searchBox {
        @apply py-3;
        @apply px-4;
        @apply rounded-full;
        @apply text-xs;
        @apply border;
    }
</style>
